import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Link,
} from "@chakra-ui/react";

const ConsentModal = (props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      size="3xl"
      scrollBehavior={"inside"}
    >
      <ModalOverlay backdropFilter="auto" backdropBlur="2px" />
      <ModalContent bgColor="#1a202c">
        <ModalHeader>
          <Text color="#30ACFB" fontSize="3xl" as="b">
            BROWN UNIVERSITY
          </Text>
          <Text fontSize="xl">CONSENT FOR RESEARCH PARTICIPATION</Text>
          <Text>Towards a Human-Level Neuromorphic Artificial Visual System</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text as="b" fontSize="xl">
            Purpose of the research:
          </Text>
          <Text fontSize="md">
            You are being asked to participate in a voluntary
            Brown University research study to measure human performance in visual
            recognition tasks. In this experiment, we seek to gain a basic understanding of
            how the visual system rapidly interprets and organizes visual scenes.
          </Text>
          <br />
          <Text as="b" fontSize="xl">
            Inclusion criteria:
          </Text>
          <Text fontSize="md">
            You must be 18 years or older to participate in this experiment. You must be a native English speaker.
          </Text>
          <br />
          <Text as="b" fontSize="xl">
            Behavioral Procedures:
          </Text>
          <Text fontSize="md">
            The task involves viewing images containing objects and clicking 
            on parts of these objects that you think are important for recognizing them.
          </Text>
          <br />
          <Text as="b" fontSize="xl">
            Time required:
          </Text>
          <Text fontSize="md">
            There is no minimal time to play the game. You can stop playing
            whenever you would like. You can also continue playing for as long as you would
            like.
          </Text>
          <br />
          <Text as="b" fontSize="xl">
            Benefits:
          </Text>
          <Text fontSize="md">
            There are no direct benefits for participating in this study. However,
            data from this study may improve our knowledge of how the brain processes
            visual scenes. The researchers intend to use data from this study for research
            projects that may appear in scientific conferences and journals.
          </Text>
          <br />
          <Text as="b" fontSize="xl">
            Compensation:
          </Text>
          <Text fontSize="md">
            By participating you will have the opportunity to win a gift card.
            Five gift cards worth a total of $250 will be drawn each week, and you can win
            one of these if you achieve a top-five score for the week. You can compete for a
            new gift card every week.
          </Text>
          <br />
          <Text as="b" fontSize="xl">
            Participant Risks:
          </Text>
          <Text fontSize="md">
            There are no anticipated risks for the participants in the current study.
          </Text>
          <br />
          <Text as="b" fontSize="xl">
            Confidentiality:
          </Text>
          <Text fontSize="md">
            Your participation in this study is confidential. By signing this
            document, you permit us to use anonymized information for scientific purposes
            including teaching and/or publication.
          </Text>
          <br />
          <Text as="b" fontSize="xl">
            Participation and Withdrawal:
          </Text>
          <Text fontSize="md">
            You do not have to participate, and you are free
            to withdraw at any time during the course of the study by closing the browser.
          </Text>
          <br />
          <Text as="b" fontSize="xl">
            Researcher:
          </Text>
          <Text fontSize="md">
            This study is being completed through the lab of:
            {"\n"}
            Dr. Thomas Serre
            {"\n"}
            Professor Cognitive, Linguistic & Psychological Sciences
            {"\n"}
            Computer Science
            {"\n"}
            Carney Institute for Brain Science Brown University Providence, RI 02912
          </Text>
          <br />
          <Text as="b" fontSize="xl">
            Research funding:
          </Text>
          <Text fontSize="md">
            This research is funded by the United States Office of Naval Research (ONR), 
              the National Science Foundation, and Brown University.
          </Text>
          <br />
          <Text as="b" fontSize="xl">
            Contact:
          </Text>

          <Text fontSize="md">
            If you have questions or concerns about this research, please email{" "}
            <Link
              href="mailto:serre-psychophysics@brown.edu"
              isExternal
              color="#30ACFB"
            >
              serre-psychophysics@brown.edu
            </Link>
            {" "}with the subject line: “ClickMe game”.
            {"\n"}
            If you have questions in regards to your rights as a participant, please contact
            Brown University’s Human Research Protection Program:
            {"\n"}
            {"\n"}
            Box 1986
            {"\n"}
            Brown University
            {"\n"}
            tel: (401) 863-3050
            {"\n"}
            toll-free: (866) 309-2095
            {"\n"}


            By clicking 'I agree' below indicates that you have understood the information
            about the experiment, are over the age of 18, and consent to your participation.
            The participation is voluntary and you may refuse to answer certain questions on
            the questionnaire and withdraw from the study at any time with no penalty. This
            does not waive your legal rights. You can print a copy of the consent form or
            save this browser window for your own record. If you have further questions
            related to this research, please contact the researcher.
          </Text>
          
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="green" mr={3} onClick={props.onAgree}>
            I agree
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConsentModal;
